<template>
  <Header
    ref="headerRef"
    :title="t('menus.Reports')"
    type="SIGNIFICANT_HOLDINGS"
    :showRegenerateButton="false"
    :updatedOn="updatedTime ?? ''"
  />

  <ReportsMenus
    ref="ReportsMenusRef"
    :params="params"
    type="SIGNIFICANT_HOLDINGS"
    :title="t('project.nav.SignificantHoldings')"
    @onChangDateTime="onChangDateTime"
  />

  <div v-loading.fullscreen.lock="loading" class="elv-reports-holdings-page-content">
    <ProcessTable
      ref="processTableRef"
      type="holdings"
      groupName="currency"
      :height="tableHeight"
      :holdingsParams="params"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import { useEntityStore } from '@/stores/modules/entity'
import ProcessTable from '../components/ProcessTable.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()
const loading = ref(false)
const processTableRef = useTemplateRef('processTableRef')
const headerRef = useTemplateRef('headerRef')
const ReportsMenusRef = useTemplateRef<InstanceType<typeof ReportsMenus>>('ReportsMenusRef')
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const tableConfigStore = useReportsTableConfigStore()

const updatedTime = computed(() => {
  return entityDetail.value?.progress.reportDoneTime
})

const tableHeight = computed(() => {
  return ReportsMenusRef.value?.isShowRegeneratedInfo ? `calc(100vh - 259px)` : `calc(100vh - 233px)`
})

const titleBottom = computed(() => {
  return ReportsMenusRef.value?.isShowRegeneratedInfo ? `0px` : `12px`
})

const params = computed(() => {
  return {
    date: ReportsMenusRef.value?.dateValue
  }
})

const onChangDateTime = async () => {
  processTableRef.value?.resetList()
}

watch(
  () => route,
  async (newValue) => {
    loading.value = true
    if (newValue.name === 'reports-significant-holdings') {
      tableConfigStore.processTableConfig = []
      await tableConfigStore.getProcessTableConfig('holdings')
    }
    setTimeout(() => {
      loading.value = false
    }, 300)
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
:deep(.elv-report-header) {
  margin-bottom: v-bind('titleBottom');
}

:deep(.elv-financial-process-table) {
  padding-top: 0px;
}

.elv-reports-holdings-page-content {
  flex: 1;
  min-height: 0;
  position: relative;
}
</style>
